<template lang="html" src="./pageSystemMonitoring.template.vue"></template>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSystemMonitoring.i18n');

export default {
  name: 'PageSystemMonitoring',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loadingTable: false,
      internalServices: [],
      search: '',
      headers: null,
    };
  },
  created() {
    this.headers = [
      { text: this.$t('status'), value: 'status' },
      { text: this.$t('hostname'), value: 'hostname' },
      { text: this.$t('service'), value: 'service' },
      { text: this.$t('created'), value: 'created' },
      { text: this.$t('uptime'), value: 'uptime' },
    ];
    this.getStatus();
  },
  methods: {
    getStatus() {
      this.loadingTable = true;
      this.$apiInstance.getInternalServicesStatus()
        .then((data) => {
          this.internalServices = data;
        }, (error) => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        })
        .finally(()=>{
          this.loadingTable = false;
        });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      if (item.created) {
        if (this.$d(new Date(item.created), 'long').toString().includes(search)) return true;
      }
      if(item.last_modified) {
        if (this.formatTime(new Date(item.created), new Date(item.last_modified)).includes(search)) return true;
      }
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    formatTime(start, end) {
      if(start instanceof Date && end instanceof Date ) {
        const time = (end.getTime() - start.getTime()) / 1000;
        const days = Math.round(time / 60 / 60 / 24);
        const hours = Math.round(time / 60 / 60 % 24);
        const minutes = Math.round(time / 60 % 60);
        const seconds = Math.round(time % 60);

        let format = '';
        format = days > 0 ? `${format}${days} ${this.$tc('days', days)}` : format;
        format = hours > 0 ? `${format} ${hours} ${this.$tc('hours', hours)}` : format;
        format = minutes > 0 ? `${format} ${minutes} ${this.$tc('minutes', minutes)}` : format;
        format = `${format} ${seconds} ${this.$tc('seconds', seconds)}`;
        return format;
      }else{
        return 'ERROR';
      }
    },
  }
};
</script>

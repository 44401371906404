var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "page-system-monitoring"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "mt-5"
  }, [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-btn', {
    staticClass: "ml-5",
    attrs: {
      "loading": _vm.loadingTable,
      "color": "primary"
    },
    on: {
      "click": _vm.getStatus
    }
  }, [_c('v-icon', [_vm._v("fas fa-sync")])], 1)], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.internalServices,
      "search": _vm.search,
      "loading": _vm.loadingTable,
      "custom-filter": _vm.customFilter,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.status`,
      fn: function (_ref) {
        var item = _ref.item;
        return [new Date(new Date(item.last_modified).getTime() + 60000).getTime() > new Date().getTime() ? _c('v-badge', {
          attrs: {
            "inline": "",
            "color": "green",
            "title": _vm.$t('serviceOnline')
          }
        }) : _c('v-badge', {
          attrs: {
            "inline": "",
            "color": "red",
            "title": _vm.$t('serviceOffline')
          }
        })];
      }
    }, {
      key: `item.created`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long")) + " ")];
      }
    }, {
      key: `item.uptime`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.formatTime(new Date(item.created), new Date(item.last_modified))) + " ")];
      }
    }], null, true)
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }